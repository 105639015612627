@use 'variables' as vars;
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rainbow{
    width: 100%;
    background: linear-gradient(90deg, rgba(255,5,5,0.25) 0%, rgba(250,163,31,0.25) 4%, rgba(242,246,58,0.25) 8%, rgba(117,238,98,0.25) 15%, rgba(78,209,238,0.25) 20%, rgba(8,47,250,0.25) 27%, rgba(145,4,255,0.25) 33.333%, rgba(8,47,250,0.25) 40%, rgba(78,209,238,0.25) 47%, rgba(117,238,98,0.25) 52%, rgba(242,246,58,0.25) 59%, rgba(250,163,31,0.25) 63%, rgba(255,5,5,0.25) 67%, rgba(250,163,31,0.25) 71%, rgba(242,246,58,0.25) 75%, rgba(117,238,98,0.25) 81%, rgba(78,209,238,0.25) 86%, rgba(8,47,250,0.25) 93%, rgba(145,4,255,0.25) 100%);
    background-size: 300% 100%;
    animation: rainbow 30s linear;
    animation-iteration-count: infinite;
    transition: .2s;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 20px;
}

@keyframes rainbow {
    0%{
        background-position: 0%;
    }
    50%{
        background-position: 50%;
    }
    100%{
        background-position: 100%;
    }
}

.container{
    width: 400px;
}

html{
    font-family: "Comfortaa", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: vars.$font_default;

    @media screen and (min-width: vars.$tablet){
        font-size: 18px;
    }

    @media screen and (min-width: vars.$desktop) {
        font-size: 24px;
    }
}

body{
    top: 0 !important;
}

.inputs{
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding: 0 80px;

    @media screen and (max-width: vars.$tablet) {
        padding: 0;
    }

    .submit{
        align-self: center;
        padding-left: 70px;
        padding-right: 70px;
    }
}

.input-group{
    width: 100%;
    &.multiple{
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;

        @media screen and (min-width: 520px) {
            grid-template-columns: 1fr 1fr;
        }

        >*{
            flex-grow: 1;
        }
    }

    &.icon{
        display: flex;
        >*:not(.icon){
            flex-grow: 1;
        }
    }

    &.gap{
        gap: 20px;
    }

    &.hidden{
        display: none;
    }
}

input, button{
    font-size: 1rem;
}

label{
    font-size: .75rem;
}

input[type=text], input[type=number], input[type=email], input[type=date], select{
    border: none;
    width: 100%;
    padding: 22px 20px;
    font-size: 1rem;
    background-color: #fff;
    filter: drop-shadow(0 1px 14px rgba(0, 0, 0, 0.11));
    position: relative;
}

fieldset{
    border: none !important;
    padding: 0;
    margin: 0;
}

.PhoneInput{
    font-size: 1rem;
    background-color: #fff;
    filter: drop-shadow(0 1px 14px rgba(0, 0, 0, 0.11));
    // padding-left: 20px;
    min-width: 0;

    .PhoneInputCountry{
        padding: 0 0 0 20px;
    }

    input[type=tel]{
        padding: 22px 20px 22px 10px;
        border:none;
    }
}

.page{
    overflow: visible;
}

.content{
    width: 90%;
    margin: 0 auto;

    @media screen and (min-width: vars.$tablet) {
        width: 720px;
    }

    @media screen and (min-width: vars.$desktop) {
        width: 1300px;
    }
}

section{
    margin-bottom: 100px !important;

    .subtitle{
        text-align: center;
    }
}

h1, h2, h3{
    text-align: center;
}

h1{
    margin: .67em 0;
}

h2{
    margin: .75em 0;
}

a{
    cursor: pointer !important;
}

.btn{
    font-size: 1em;
    text-transform: uppercase;
    padding: 15px 40px;
    border-radius: 200px;
    border: 2px solid vars.$primary;
    color: vars.$primary;
    background-color: #fff;

    text-decoration: none;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    transition-duration: .2s;

    &:hover{
        border: 2px solid vars.$primary_hover;
        background-color: rgba(vars.$primary_hover, .2);
        color: vars.$primary_hover;
    }

    &.primary{
        background-color: vars.$primary;
        color: #fff;

        &:hover{
            background-color: vars.$primary_hover;
        }
    }
}

.btn.close{
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;

    width: 24px;
    height: 24px;

    background-color: transparent;
    background-image: url(./assets/ph_x-bold.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;

    position: absolute;
    top: 25px;
    right: 25px;

    transition-duration: .5s;

    &:hover{
        transform-origin: 50% 50%;
        transform: rotateZ(180deg);
        scale: 1.5;
    }
}